.navbar{
    background: #82828256;
    padding: 20px 0px
}
.nav-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.nav-container .right .name{
    color: var(--primaryColor);
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    padding-bottom: 10px;
}
.nav-container .right .logout{
    border: none;
    background: rgba(121, 17, 38, 0.116);
    color:crimson;
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;
    width: 100px;
    margin:auto 0 auto auto
}