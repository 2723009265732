.dashboard {
    margin: 20px 0px;
}

.dashboard-page {
    background: rgb(226, 222, 222);
    padding: 30px 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 4mm ridge var(--purpleColor);
}
.image{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
img{
    width: 100px;
}

.dashboard-content {
    background: rgb(240, 79, 15);
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin: 5px;
    text-decoration: none;
}

.dashboardContent2 {
    background: rgba(240, 79, 15, 0.616);
}

.dashboardContent3 {
    background: rgb(117, 221, 31);
}

.dashboardContent4 {
    background: rgb(16, 204, 10);
}

.dashboardContent5 {
    background: rgb(5, 250, 250);
}

.dashboardContent6 {
    background: rgba(22, 228, 228, 0.548);
}

.dashboardContent7 {
    background: rgb(142, 11, 218);
}

.dashboardContent8 {
    background: rgba(142, 11, 218, 0.596);
}

.dashboard-nav {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.dashboard-nav:hover {
    color: var(--primaryColor);
    text-decoration: none;
}

.dashboard-content:hover {
    background: rgb(98, 0, 255);
    cursor: pointer;
}

.title {
    color: var(--mainDark);
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: thick double var(--primaryColor);
    margin-bottom: 30px;
}