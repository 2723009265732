.content-container {
    width: 100%;
    margin: 50px 0px 40px 0px;
}

.content-display {
    width: 70%;
    margin: 0 auto;
    font-family: var(--textFamily);
    color: var(--mainBlack)
}

.content-display h2 {
    font-family: var(--textFamily);
    color: var(--mainDark);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 15px;
}
.content-display .subtitle{
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 20px;
}

.form-input {
    border: 1px solid var(--primaryColor);
    padding: 30px 10px;
    box-shadow: 10px 15px var(--primaryColor);
    border-radius: 5px;
}

.inputfield {
    width: 100%;
    height: 50px;
    outline: none;
    border: 1px solid var(--mainGrey);
    background: #fff;
    padding-left: 8px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 12px;
}

.inputfield:focus,
.textareafield:focus {
    border: 1px solid var(--purpleColor);
}

.textareafield {
    width: 100%;
    outline: none;
    border: 1px solid var(--mainGrey);
    background: #fff;
    padding-left: 8px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 12px;
}

.form-input label {
    font-size: var(--textFamily);
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    color: var(--mainDark)
}

.input-div {
    padding: 5px 0px;
}

.button-div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.submitButton {
    padding: 10px 20px;
    background: var(--primaryColor);
    color: #fff;
    font-size: 16px;
    border: none;
    transition: 0.4s all ease;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    border-radius: 5px;
}

.submitButton:hover {
    background: var(--purpleColor);
}

.clear {
    background: rgba(207, 9, 9, 0.795);
}

.clear:hover {
    background: rgb(247, 8, 8);
}

.post-content {
    padding: 30px 0px;
    text-align: center;
}



.post-content .card img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.post-content .card .imageview{
    width: 100%;
    height: 200px;
    overflow: hidden;
    padding: 10px;
}

.post-content .name {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    padding: 10px 0px;
    border-bottom: thick double var(--primaryColor);
    margin-bottom: 20px
}

.post-content .location {
    padding-bottom: 20px;
}

.post-content .action {
    margin-bottom: 30px;
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
}

.post-content .edit {
    background: var(--primaryColor);
    width: 70px;
    color:#fff;
    border: none;
    border-radius: 5px;
}

.post-content .edit:hover {
    background: var(--purpleColor);
}

.post-content .action .del-btn {
    background-color: crimson;
    border-radius: 5px;
    width: 70px;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .content-display {
        width: 95%;
        margin: 0 auto;
        font-family: var(--textFamily);
        color: var(--mainBlack)
    }
}

.password{
    position: relative;
}
.password .showPassword{
    position: absolute;
    right: 20px;
    top: 15px;
}
.darkicon{
    color: gray;
    font-size: 18px;
    cursor: pointer;
    transition: 0.3s all ease;
}
.lighticon{
    color: rgba(31, 127, 151, 0.541);
    font-size: 18px;
    cursor: pointer;
    transition: 0.3s all ease;
}
.delete-btn-disable{
    border: none;
    background: rgba(122, 12, 34, 0.24);
    pointer-events: none;
}